<template>
  <v-dialog
    v-model="dialogModal"
    max-width="500px"
    :persistent="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Documents Setup</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="level"
                label="Level"
                name="Level"
                v-validate="'required'"
                :items="levels"
              ></v-select>
              <span style="color: red;"
                v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="title"
                label="Title"
                name="Title"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="selected"
                label="Choose Type"
                name="Type"
                v-validate="'required'"
                :items="settings"
              ></v-select>
              <span style="color: red;"
                v-show="errors.has('Type')" v-if="valid">{{ errors.first('Type') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="size"
                label="Size in megabytes"
                name="Size"
                v-validate="'required|numeric'"
              ></v-text-field>
              <span style="color: red;" v-show="errors.has('Size')" v-if="valid">{{ errors.first('Size') }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="validate"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'createDocumentSettings',
  props: {
    dialogModal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      size: '',
      title: '',
      selected: null,
      settings: [{ text: 'Pdf', value: 1 }],
      logger: [],
      header: '',
      valid: true,
      levels: [
        { text: 'Level1', value: 1 },
        { text: 'Level2', value: 2 },
        { text: 'Level3', value: 3 },
        { text: 'Level4', value: 4 },
        { text: 'Level5', value: 5 },
      ],
      level: null,
    }
  },
  methods: {
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    close () {
      this.title = ''
      this.code = ''
      this.selected = ''
      this.valid = false
      this.$emit('offModal', false)
    },
    async save () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      if (this.title === '' || this.size === '' || this.selected === '') {
        this.validate()
      } else {
        const data = {
          title: this.title,
          size: this.size,
          type: this.selected,
          level: this.level
        }
        try {
          const res = await this.axios({
            url: 'manipulate_document_settings',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.$emit('getResponse', res.data)
            this.title = ''
            this.size = ''
            this.selected = ''
            this.valid = false
            this.close()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
            this.title = ''
            this.code = ''
            this.selected = ''
            this.level = ''
            this.valid = false
          } else {
            console.log(e)
          }
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
